import './PatientList.scss';

import { AppShell, Table } from '@mantine/core';
import dayjs from 'dayjs';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { IS_APP_QOLIBRI } from '../../application';
import {
  ActivityIndicator,
  ArrowDown,
  Button,
  ButtonClass,
  ErrorApiMessage,
  InformationApiMessage,
  Plus,
  Search,
  TextInput,
} from '../../components';
import {
  PatientWithLastActivityDate,
  useSearchPatients,
} from '../../services/api/patient';
import { COLORS } from '../../theme/colors';
import { AddPatientModal } from './components/AddPatientModal/AddPatientModal';
import { Header } from './components/Header/Header';
import { Status, StatusTag } from './components/StatusTag/StatusTag';

const NUMBER_OF_FETCHED_PATIENTS = 20;
const DEFAULT_PATIENT_LIST_OFFSET = 0;
const WARNING_THRESHOLD_IN_DAYS = 7;

const SEARCHED_NAME_DEBOUNCE_DELAY_IN_MS = 200;

const getDayDifferenceWithToday = (lastActivityDate: string): number => {
  const today = dayjs();
  const differenceInDays = today.diff(dayjs(lastActivityDate), 'day');
  return differenceInDays;
};

const hasBeenMoreThanAMonth = (lastActivityDate: string): boolean => {
  const today = dayjs();
  const differenceInMonths = today.diff(dayjs(lastActivityDate), 'month', true);
  return differenceInMonths > 1;
};

export const PatientList = () => {
  const [searchedName, setSearchedName] = useState<string>('');
  const [debouncedSearchedName] = useDebounce(
    searchedName,
    SEARCHED_NAME_DEBOUNCE_DELAY_IN_MS,
  );

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useSearchPatients(
    NUMBER_OF_FETCHED_PATIENTS,
    DEFAULT_PATIENT_LIST_OFFSET,
    debouncedSearchedName,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAddPatientModalOpened, setIsPatientModalOpened] =
    useState<boolean>(false);

  const getTagToDisplay = (lastActivityDate: string | null): ReactElement => {
    if (lastActivityDate === null) {
      return (
        <StatusTag
          text={t('patientList.tableContent.lastActivityDate.noConnection')}
          status={Status.NEUTRAL}
        />
      );
    }
    if (hasBeenMoreThanAMonth(lastActivityDate)) {
      return (
        <StatusTag
          text={t('patientList.tableContent.lastActivityDate.overAMonth')}
          status={Status.ERROR}
        />
      );
    }

    const differenceInDays: number =
      getDayDifferenceWithToday(lastActivityDate);
    return (
      <StatusTag
        text={
          differenceInDays === 0
            ? t('patientList.tableContent.lastActivityDate.today')
            : t('patientList.tableContent.lastActivityDate.lessThanAMonth', {
                differenceInDays,
              })
        }
        status={
          differenceInDays < WARNING_THRESHOLD_IN_DAYS
            ? Status.SUCCESS
            : Status.WARNING
        }
      />
    );
  };

  const headerRow = (
    <tr>
      <th key="lastName">
        <p className="patient-table-header">
          {t('patientList.tableHeader.lastName')}
        </p>
      </th>
      <th key="firstName">
        <p className="patient-table-header">
          {t('patientList.tableHeader.firstName')}
        </p>
      </th>
      <th key="lastActivityDate">
        <p className="patient-table-header">
          {t('patientList.tableHeader.lastActivityDate')}
        </p>
      </th>
    </tr>
  );

  const allPatients =
    data !== undefined ? data.pages.flatMap(({ patients }) => patients) : [];

  const rows = allPatients.map((patient: PatientWithLastActivityDate) => (
    <tr
      key={patient.id}
      onClick={() => navigate(`/patient/${patient.id}/followup`)}
      className="p2 patient-list-table-row"
    >
      <td>
        <p>{patient.lastName.toUpperCase()}</p>
      </td>
      <td>
        <p>{patient.firstName}</p>
      </td>
      <td className="patient-list-table-cell">
        <div className="patient-list-tag-container">
          {getTagToDisplay(patient.lastActivityDate)}
        </div>
      </td>
    </tr>
  ));

  const seeMoreButton = isFetchingNextPage ? (
    <ActivityIndicator />
  ) : (
    <Button
      buttonClass={ButtonClass.TERTIARY}
      RightIcon={ArrowDown}
      text={t('patientList.tablePagination.seeMore')}
      onClick={fetchNextPage}
      color={COLORS.primary._500}
    />
  );

  const errorMessage = (
    <ErrorApiMessage
      title={t('apiMessage.serverError.errorOccurred')}
      subtitle={t('apiMessage.serverError.tryAgainLater')}
      className="api-message-display"
    />
  );

  const addPatientButton = IS_APP_QOLIBRI ? (
    <></>
  ) : (
    <Button
      buttonClass={ButtonClass.PRIMARY}
      text={t('patientList.addPatient')}
      LeftIcon={Plus}
      onClick={() => setIsPatientModalOpened(true)}
      data-testid={'add-patient-button'}
    />
  );

  const noPatientMessage = (
    <InformationApiMessage
      className="api-message-display"
      title={t('apiMessage.noPatientFound')}
    />
  );

  const addPatientModal = (
    <AddPatientModal
      opened={isAddPatientModalOpened}
      onClose={() => setIsPatientModalOpened(false)}
    />
  );

  return (
    <AppShell padding={0} header={<Header />}>
      <div className="patient-list-container">
        <div className="patient-list-button-searchbar-wrapper">
          <TextInput
            inputTestId="patient-list-searchbar"
            isValid
            placeholder={t('patientList.searchPatient')}
            containerClassName="patient-list-searchbar"
            leftIcon={<Search color={COLORS.primary._500} />}
            value={searchedName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchedName(event.currentTarget.value)
            }
          />
          {addPatientButton}
        </div>
        <h1 className="patient-list-table-title">
          {t('patientList.tableTitle.allMyPatients')}
        </h1>
        <div className="patient-list-table-container shadow-medium">
          {isLoading ? (
            <div className="api-message-display">
              <ActivityIndicator />
            </div>
          ) : (
            <Table>
              <thead>{headerRow}</thead>
              <tbody>{rows}</tbody>
            </Table>
          )}
          {isError && errorMessage}
          {!isError &&
            !isLoading &&
            allPatients.length === 0 &&
            noPatientMessage}

          {hasNextPage && seeMoreButton}
        </div>
        {addPatientModal}
      </div>
    </AppShell>
  );
};
