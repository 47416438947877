import { useMutation } from '@tanstack/react-query';
import type { UserCredential } from 'firebase/auth';
import { AuthErrorCodes, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { auth } from '../../firebaseConfig';
import { isFirebaseAuthError } from './errors';

interface SignInData {
  email: string;
  password: string;
}

const signInFn = async ({
  email,
  password,
}: SignInData): Promise<UserCredential> => {
  return signInWithEmailAndPassword(auth, email, password);
};

const AUTH_SIGN_IN_ERROR_CODES_TO_DISPLAY_SPECIFIC_ERROR = [
  AuthErrorCodes.INVALID_PASSWORD,
  AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER,
  AuthErrorCodes.USER_DELETED,
  AuthErrorCodes.INVALID_LOGIN_CREDENTIALS,
];

type AuthSignInError = {
  code: (typeof AUTH_SIGN_IN_ERROR_CODES_TO_DISPLAY_SPECIFIC_ERROR)[number];
};

const isAuthSignInError = (error: unknown): error is AuthSignInError => {
  return (
    isFirebaseAuthError(error) &&
    AUTH_SIGN_IN_ERROR_CODES_TO_DISPLAY_SPECIFIC_ERROR.some(
      (code) => code === error.code,
    )
  );
};

type SignInError = {
  message: string;
};

export const useSignIn = () => {
  const { t } = useTranslation();

  const [signInError, setSignInError] = useState<SignInError | null>(null);

  const onError = (error: unknown) => {
    if (isAuthSignInError(error)) {
      setSignInError({
        message: t(`errors.firebase.signIn.${error.code}.message`),
      });
    } else {
      setSignInError({
        message: t('errors.unknownError.message'),
      });
    }
  };

  const { mutate: signInMutate, ...otherMutationResult } = useMutation<
    UserCredential,
    unknown,
    SignInData,
    unknown
  >(['signIn'], signInFn, { onError });

  const signIn = (signInData: SignInData) => signInMutate(signInData);

  return {
    signIn,
    signInError,
    setSignInError,
    ...otherMutationResult,
  };
};
